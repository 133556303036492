
// Código elaborado por: Nelson Braga
// Cargo: Desenvolvedor Fullstack
// Data: 22/03/2024

import React from "react";
import { Routes, Route, Link } from 'react-router-dom'
import Abertura from "./components/Abertura";
import Memorial from "./components/Memorial";
import Erro from "./components/Erro";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Abertura />}></Route>
        <Route path="/locais" element={<Memorial />}></Route>
        <Route path="/*" element={<Erro />}></Route>
      </Routes>
    </>
  )
}

export default App