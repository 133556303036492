
// Código elaborado por: Nelson Braga
// Cargo: Desenvolvedor Fullstack
// Data: 22/03/2024

import React from 'react'
import '../estilos/Erro.css'

// Este arquivo contém a página de erro do sistema

function Erro() {
    return (
        <div className="container-fluid">
            <div>
                {/* Página de Erro */}
                <div className='row'>
                    <div id="titulo" className='col-12'>
                        <br />
                        <br />
                        <br />
                        <h1>Erro 404 - Página Não Encontrada !</h1>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <a className="btnRetornar" href="/">RETORNAR</a>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Erro